import * as React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react'; 
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Results from './Results';
import { quizDataSets } from '../data/quizData';  // Importing all quiz data sets
import LinearProgressWithLabel from './QuizProgress'; // Import the new component
import LinearProgress from '@mui/material/LinearProgress';





export default function Quiz() {
  const [activeStep, setActiveStep] = useState(0);
  const [score, setScore] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [selectedQuiz, setSelectedQuiz] = useState(Object.keys(quizDataSets)[0]); // Set default quiz
  const [quizData, setQuizData] = useState(quizDataSets[selectedQuiz]); // Quiz data based on selection
  const [userAnswers, setUserAnswers] = useState(Array(quizData.length).fill(''));
  const [hasPrevious, setHasPrevious] = useState(false);

  useEffect(() => {
    // Update quizData and reset when a new quiz is selected
    setQuizData(quizDataSets[selectedQuiz]);
    setActiveStep(0);
    setScore(0);
    setSelectedOption('');
    setShowResults(false);
    setUserAnswers(Array(quizDataSets[selectedQuiz].length).fill(''));
  }, [selectedQuiz]);

  const saveScoreToAccount = async (quizScore, quizName) => {
    const token = localStorage.getItem('authToken');
  
    if (!token) {
      console.error('User is not authenticated');
      return;
    }
  
    try {
      const response = await axios.post('http://localhost:5000/api/save-score', 
        { 
          quizName,
          score: quizScore,
          date: new Date().toISOString(), // Save the current date and time
        }, 
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      console.log('Score and quiz name saved successfully:', response.data);
    } catch (error) {
      console.error('Failed to save score:', error);
    }
  };
                
  const handleNext = () => {
    let newScore = score;
    if (selectedOption === quizData[activeStep].answer) {
      newScore += 1;
      setScore(newScore);
    }
  
    setUserAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[activeStep] = selectedOption;
      return updatedAnswers;
    });
  
    setSelectedOption('');
  
    if (activeStep === quizData.length - 1) {
      setShowResults(true);
      saveScoreToAccount(newScore, selectedQuiz);
    } else {
      setActiveStep((prevActiveStep) => {
        const newStep = prevActiveStep + 1;
        setHasPrevious(newStep > 0); // Update hasPrevious state
        return newStep;
      });
    }
  };
  
  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => {
      const newStep = prevActiveStep - 1;
      setHasPrevious(newStep > 0); // Update hasPrevious state
      return newStep;
    });
    setSelectedOption(userAnswers[activeStep - 1]); // Restore previously selected answer
  };
        
  const handleReset = () => {
    setActiveStep(0);
    setScore(0);
    setSelectedOption('');
    setShowResults(false);
    setUserAnswers(Array(quizData.length).fill(''));
  };
  
  const handleKeyPress = (event) => {
    const key = event.key.toUpperCase();
    const options = ['A', 'B', 'C', 'D'];
    const optionIndex = options.indexOf(key);
    if (optionIndex !== -1 && quizData[activeStep].options[optionIndex]) {
      setSelectedOption(quizData[activeStep].options[optionIndex]);
    } else if (key === 'ENTER' || key === ' ') {
      event.preventDefault(); // Prevent default behavior
      handleNext();
    }
  };

  useEffect(() => {
    if (!showResults) {
      window.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [activeStep, selectedOption, showResults]);

 // Calculate progress as a percentage
  const progress = ((activeStep + 1) / quizData.length) * 100;
  const questionNumber = activeStep + 1;
  const totalQuestions = quizData.length;


  return (
    <Box sx={{ width: '100%', alignItems: 'center' }}>
      {/* Quiz Selection Dropdown */}
      {/* <Box sx={{ mb: 3 }}>
        <Typography variant="h6">Select Quiz:</Typography>
        <select value={selectedQuiz} onChange={(e) => setSelectedQuiz(e.target.value)}>
          {Object.keys(quizDataSets).map((quizName) => (
            <option key={quizName} value={quizName}>
              {quizName}
            </option>
          ))}
        </select>
      </Box> */}

      {/* Stepper */}
      {/* <Stepper activeStep={activeStep} alternativeLabel>
        {quizData.map((_, index) => (
          <Step key={index}>
            <StepLabel>Step {index + 1}</StepLabel>
          </Step>
        ))}
      </Stepper> */}


      {/* Progress Bar */}
      <Box
  sx={{
    width: '90%',
    mb: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mx: 'auto' // Centers the Box horizontally within its parent
  }}
>
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      mb: 1,
      maxWidth: '600px' // Optional: limits width for better alignment
    }}
  >
    <Typography variant="body2">{` ${questionNumber}`}</Typography>
    <Typography variant="body2">{`${questionNumber} / ${totalQuestions}`}</Typography>
  </Box>
  <LinearProgress
    variant="determinate"
    value={progress}
    sx={{ width: '100%', maxWidth: '600px' }} // Optional: limits width for better alignment
  />
</Box>
      

      {/* Fade effect for the quiz content */}
      <Fade in={!showResults}>
        <Box display={showResults ? 'none' : 'block'}>
          <Card
            sx={{
              borderRadius: '16px',
              mt: 4,
              textAlign: 'center',
              padding: '20px',
            }}
          >
            <CardContent>
              {/* Question */}
              <Typography variant="h4" fontWeight="bold" sx={{ mb: 4 }}>
                {quizData[activeStep].question}
              </Typography>

              {/* Options */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  gap: '16px',
                  mb: 2,
                }}
              >
                {quizData[activeStep].options.map((option, index) => (
                  <Box key={index} sx={{ textAlign: 'center' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                      {String.fromCharCode(65 + index)}.
                    </Typography>
                    <Button
                      variant={selectedOption === option ? 'outlined' : 'contained'}
                      sx={{
                        width: '150px',
                        height: '50px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        borderColor: '#000',
                      }}
                      onClick={() => setSelectedOption(option)}
                    >
                      {option}
                    </Button>
                  </Box>

                ))}
              </Box>

<Box
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 4,
    gap: '20px', // Adjusts spacing between the buttons
  }}
>
  {activeStep > 0 && ( // Show Previous button only if not on the first step
    <Button
      variant="contained"
      onClick={handlePrevious}
      sx={{ fontSize: '18px', width: '100px' }} // Adjust width if needed
    >
      Previous
    </Button>
  )}
  <Button
    variant="contained"
    onClick={handleNext}
    disabled={!selectedOption}
    sx={{ fontSize: '18px', width: '100' }} // Larger width for Next button
  >
    {activeStep === quizData.length - 1 ? 'Finish' : 'Next'}
  </Button>
</Box>




              
            </CardContent>
          </Card>
        </Box>
      </Fade>

      {/* Results */}
      {showResults && (
        <Fade in={showResults}>
          <Box
            textAlign="center"
            mt={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minHeight: '80vh',
            }}
          >
            <Typography variant="h4">Results</Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
              You scored {score} out of {quizData.length}
            </Typography>
            <Box
              sx={{
                mt: 4,
                padding: '16px',
                border: '2px solid black',
                borderRadius: '8px',
                textAlign: 'center',
                width: '200px',
                backgroundColor: '#f0f0f0',
              }}
            >
              <Typography variant="h5">Total Score</Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                sx={{ color: 'primary.main' }}
              >
                {score}/{quizData.length}
              </Typography>


              <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                {((score / quizData.length) * 100).toFixed(1)}%
              </Typography>
            </Box>


            
            <Box sx={{ mt: 4, width: '80%' }}>
              <Results questions={quizData} userAnswers={userAnswers} />
            </Box>
            <Button variant="contained" onClick={handleReset} sx={{ mt: 4 }}>
              Retake Quiz
            </Button>
          </Box>
        </Fade>
      )}
    </Box>
  );
}
