import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Chart1 from '../components/charts/chart1';
import Chart2 from '../components/charts/chart2';
import Chart5 from '../components/charts/chart5';
import Chart7 from '../components/charts/chart7';
import Table3 from '../components/tables/table3';
import BasicInfo from '../components/BasicInfo';
import DemoInfo from '../components/DemoInfo';
import Card from '@mui/material/Card';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import { useMediaQuery, useTheme as useMuiTheme } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React, { useRef ,useEffect, useState } from 'react';
import axios from 'axios'; // Ensure you have installed axios
// import { countryData } from '../data/countrydata'; // Import countryData

export default function Dashboard() {
  const [selectedRegion, setSelectedRegion] = useState('africa'); // Default to Africa
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCountryData, setSelectedCountryData] = useState(null);
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [tabIndex, setTabIndex] = useState(1); // State for tabs
  const [currentCountryData, setCurrentCountryData] = useState([]); // Will hold data fetched from the API
  const [selectedCountry, setSelectedCountry] = useState('');
  const [filteredCountryData, setFilteredCountryData] = useState([]);

   // Create refs for the sections
 const section1Ref = useRef(null);
 const section2Ref = useRef(null);
 const theme = useMuiTheme();
 const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
 const [view, setView] = useState('list');


// Fetch country data from the API
useEffect(() => {
  const fetchCountries = async () => {
    try {
      const response = await axios.get('https://aeri-api.onrender.com/api/countries'); // Fetch from API
      // const response = await axios.get('https://your-app-name.onrender.com/api/countries'); // Fetch from API
      setCurrentCountryData(response.data); // Store fetched country data
      // Initially filter based on default region
      const initialFilteredCountries = response.data.filter(
        country => country.region === 'asia'
      );
      setFilteredCountryData(initialFilteredCountries);
      if (initialFilteredCountries.length > 0) {
        setSelectedCountry(initialFilteredCountries[0].code);
        setSelectedCountryData(initialFilteredCountries[0]);
      }
    } catch (error) {
      console.error('Error fetching country data:', error);
    }
  };

  fetchCountries();
}, []);


  const getRegionForTab = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return 'asia';
      case 1:
        return 'africa';
      case 2:
        return 'europe';
      case 3:
        return 'america';
      case 4:
        return 'europe';
      default:
        return 'africa'; // Default region
    }
  };

  const region = getRegionForTab(tabIndex);

 // Filter countryData based on the selectedRegion
//  const filteredCountryData = currentCountryData.filter(country => country.region === selectedRegion);

 useEffect(() => {
  const filteredCountries = currentCountryData.filter(country => country.region === selectedRegion);
  
  setFilteredCountryData(filteredCountries);  // Update the filtered country data
  
  if (filteredCountries.length > 0) {
    // Set the first country's data as selected when a new region is chosen
    setSelectedCountry(filteredCountries[0].code);
    setSelectedCountryData(filteredCountries[0]);
  } else {
    // Reset the selection if there are no countries available for the selected region
    setSelectedCountry('');
    setSelectedCountryData(null);
  }
}, [selectedRegion, currentCountryData]);


// Handle region change from the ToggleButtonGroup
// Handle region change from the ToggleButtonGroup
const handleRegionChange = (event, newRegion) => {
  console.log('Region change event:', event); // Log the event object
  console.log('New Region:', newRegion); // Log the new region value

  if (newRegion !== null) {
    setSelectedRegion(newRegion);

    // Filter countries based on the new selected region
    const filteredCountries = currentCountryData.filter(
      country => country.region === newRegion
    );

    console.log('Filtered Countries:', filteredCountries); // Log the filtered countries

    setFilteredCountryData(filteredCountries);

    if (filteredCountries.length > 0) {
      // Automatically select the first country in the filtered list
      setSelectedCountry(filteredCountries[0].code);
      setSelectedCountryData(filteredCountries[0]);
    } else {
      // Reset if no countries available for the selected region
      setSelectedCountry('');
      setSelectedCountryData(null);
    }
  }
};
  
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);

    // Update the region and countries when tab changes
    const regionForTab = getRegionForTab(newValue);
    setSelectedRegion(regionForTab);

    const filteredCountryData = currentCountryData.filter(row => row.region === regionForTab);
    if (filteredCountryData.length > 0) {
      setSelectedCountry(filteredCountryData[0].code);
      setSelectedCountryData(filteredCountryData[0]);
    } else {
      setSelectedCountry('');
      setSelectedCountryData(null);
    }
  };


// Handle country selection from the dropdown
const handleCountryChange = event => {
  const selectedCode = event.target.value;
  setSelectedCountry(selectedCode);

  // Find and set the selected country data
  const country = filteredCountryData.find(c => c.code === selectedCode);
  setSelectedCountryData(country || null);
};

  const handleRowSelect = (countryCode) => {
    setSelectedCountries([countryCode]); // Set the selected country

    // Find and set the selected country data
    const country = currentCountryData.find((c) => c.code === countryCode);
    setSelectedCountryData(country || null);
  };



 
   // Function to scroll to a specific section
   const scrollToSection = (sectionRef) => {
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const sectionRefs = [section1Ref, section2Ref]; // Array of section refs





  // Update filteredCountryData based on selectedRegion
  useEffect(() => {
    const filteredCountries = currentCountryData.filter(
      country => country.region === selectedRegion
    );
    setFilteredCountryData(filteredCountries);

    // Automatically select the first country if available
    if (filteredCountries.length > 0) {
      setSelectedCountry(filteredCountries[0].code);
    } else {
      setSelectedCountry('');
    }
  }, [selectedRegion, currentCountryData]);

  

  
  
  // Section pagination //

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabValue = queryParams.get('tab');

    switch (tabValue) {
      case 'asia':
        setTabIndex(0);
        break;
      case 'africa':
        setTabIndex(1);
        break;
      case 'america':
        setTabIndex(2);
        break;
      case 'europe':
        setTabIndex(3);
        break;
      default:
        setTabIndex(1); // Default tab if no match
    }
  }, [location.search]);


  const handleChange2 = (event, newValue) => {
    setValue(newValue);
    
    
  };


  
  


  return (
    <Box 
    
    
    
    sx={{ 
      backgroundColor: '',
      padding: 0 }}>
      
      
      
      <Box 

      
      
      
      
      sx={{ 
        width: '100%',

        
        
        flexGrow: 1 }}>
        <Grid 
        sx={{   
          flexDirection: isMobile ? 'column' : 'row', // Horizontal on mobile, vertical otherwise
        }}
        
        
        
        container spacing={0}>
          {/* GRID ONE */}
          <Grid item xs={12} md={4}  xl={2} 
          sx={{
            
             width: '100%',
             border: '0px solid gray' }}>
              <Box
                sx={{
                  justifyContent: 'center',
                  backgroundColor: 'inherit', 
                  alignItems: 'center',


                }}
              >
                <CardContent height='200px'>


                  <Box
  sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
>  
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Global Economic Dashboard
                  </Typography>
                  </Box>



                  <Divider />


                </CardContent>


                {/* BOXX */}
                <Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '',
    paddingLeft: '5px',  // Optional padding for better spacing
    paddingRight: '5px',  // Optional padding for better spacing
    gap: '10px',  // Optional padding for better spacing
    width: '100%',   // Ensure the box takes full width of its parent
  }}
>





<Box 
    sx={{  
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',  // Aligns content to the left
      width: '100%',
      paddingTop: '8px',  // Optional padding for spacing
    }}                

> 
 <ToggleButtonGroup
        color="primary"
        value={selectedRegion}
        exclusive
        onChange={handleRegionChange}
        aria-label="Region"
      >
        <ToggleButton value="asia">Asia</ToggleButton>
        <ToggleButton value="africa">Africa</ToggleButton>
        <ToggleButton value="europe">Europe</ToggleButton>
        {/* Add more regions as needed */}
      </ToggleButtonGroup></Box>





<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="country-select-label">Country</InputLabel>
        <Select
          labelId="country-select-label"
          id="country-select"
          value={selectedCountry}
          label="Country"
          onChange={handleCountryChange}
        >
          {filteredCountryData.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              <ListItemText primary={country.name} />
              <ListItemIcon>
                <img
                  src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                  alt={`${country.name} flag`}
                  style={{ width: 20, height: 15, marginRight: 10 }}
                />
              </ListItemIcon>
            </MenuItem>
          ))}
        </Select>
      </FormControl>




  <CardActions
    sx={{  
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',  // Aligns content to the left
      width: '100%',
      paddingTop: '8px',  // Optional padding for spacing
    }}                
  > 





    <Tabs 
      value={value} 
      onChange={handleChange2} 
      aria-label="icon tabs example"
      sx={{
        '& .MuiTab-root': {
          minWidth: 0, // Optional: Adjust tab width
          alignItems: ' center'
        },
      }}
    >
      <Tab 
        // icon={<PhoneIcon sx={{ fontSize: 20 }} />} // Adjust icon size here
        aria-label="phone" 
        label="Economic"
        sx={{ fontSize: '0.75rem' }} // Adjust label text size here if needed
      />
      {/* <Tab 
        // icon={<FavoriteIcon sx={{ fontSize: 20 }} />} // Adjust icon size here
        aria-label="favorite" 
        label="Demographic"
        sx={{ fontSize: '0.75rem' }} // Adjust label text size here if needed
      /> */}
    </Tabs>
  </CardActions>
</Box>



<CardActions
sx={{  
  alignItems: 'center',
  justifyContent: 'center',
  bgcolor: '' }}                
                >
  </CardActions>
      </Box>
  </Grid>



  <Divider 
  variant="middle" 
   direction={{ xs: 'column', sm: 'row' }} 
   flexItem />



{/* SECOND GRID */}
<Grid
item
xs={12}
md={8}
xl={5}
sx={{
backgroundColor: '',
display: 'flex',
justifyContent: 'center',
alignItems: 'center',
width: '100%',
border: '0px solid gray',
}}
>
<Box sx={{ justifyContent: 'left', backgroundColor: 'inherit' }}>

{/* Conditionally render the content based on selected tab */}
<BasicInfo 
scrollToSection={scrollToSection}
sectionRefs={sectionRefs}
selectedCountryData={selectedCountryData}
 />
 
 
  {/* Pass selectedCountryData as prop */}  {/* Show BasicInfo when the first tab is selected */}

{/* {value === 1 &&  
<BasicInfo 
scrollToSection={scrollToSection}
sectionRefs={sectionRefs}
selectedCountryData={selectedCountryData}



/>}  */}


</Box>

</Grid>


          {/* 4 */}
          <Grid
          
  item
  xs={12} // Full width on small screens
  md={2} // Full width on medium screens (if needed)
  xl={4}  // Fixed width on extra-large screens
  
  sx={{
    backgroundColor: '',
    display: 'flex',
    justifyContent: 'center', // Center contents horizontally
    border: '0px solid gray',
    padding: '10px',
    alignItems: 'center',

    width: '100%', // Ensure it spans 100% width within its container
    height: '100%', // Ensure it spans 100% width within its container
  }}
>

  <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>  
    {value === 0 && (
      <Stack
      spacing={1}
      direction={{ xs: 'column', sm: 'row' }} // Column direction for xs screens, row for sm and above        spacing={1}
        sx={{ 
          width: '100%', 
          height: '100%', // Ensure the Stack takes up the full height of its container
          overflowX: 'none', // Add horizontal scrolling if necessary
        }}
      >
{/* HERE PIECHART */}


        <Box sx={{ flex: '1 1 100%', backgroundColor: '', maxWidth: '100%', overflow: 'hidden' }}>
        <Chart1 
        selectedCountry={selectedCountry}

        region={selectedRegion}  

         />          
         </Box>



         <Box>  
              <Divider 
        variant="middle" 
        direction={{ xs: 'column', sm: 'row' }} 
        orientation={{ xs:' horizontal', md:' vertical'  }}
        flexItem />
   </Box>



  {/* <Box sx={{ flex: '1 1 50%', backgroundColor: '', width: '100%', overflow: 'hidden' }}>
    <Chart4 />
  </Box> */}

      </Stack>
    )}


    {value === 1 && (
      <Stack
      spacing={1}
 direction={{ xs: 'column', sm: 'row' }} // Column direction for xs screens, row for sm and above        spacing={1}
        sx={{ 
          width: '100%', 
          height: '100%', // Ensure the Stack takes up the full height of its container
          overflowX: 'auto', // Add horizontal scrolling if necessary
        }}
      >
        <Box sx={{ flex: '1 1 50%', maxWidth: '100%', overflow: 'hidden' }}>
          <Chart1 />
        </Box>

        <Divider
      orientation={{ xs: 'horizontal', xl: 'vertical' }} // Horizontal at xs, vertical at sm and above

      flexItem

    />
        <Box sx={{ flex: '1 1 50%', maxWidth: '100%', overflow: 'hidden' }}>
          <Chart5 />
        </Box>
      </Stack>
    )}


  </Box>




</Grid>




          {/* Tabs table */}
          <Grid item xs={12} md={12} xl={12} sx={{ backgroundColor: '', height: '100%', border: '0px solid gray' }}>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="tabs label example"
        sx={{
          '& .MuiTab-root': {
            minHeight: 48,
            '& .MuiTab-icon': {
              fontSize: 20,
            },
          },
          '& .MuiTabs-indicator': {
            backgroundColor: 'lightgreen',
          },
        }}
      >
        <Tab
          iconPosition="start"
          label="Asia"
          sx={{
            '&.Mui-selected': {
              color: 'orange',
            },
            '&.MuiTab-root': {
              color: 'grey',
            },
          }}
        />
        <Tab
          iconPosition="start"
          label="Africa"
          sx={{
            '&.Mui-selected': {
              color: 'green',
            },
            '&.MuiTab-root': {
              color: 'grey',
            },
          }}
        />
        <Tab
          iconPosition="start"
          label="Europe"
          sx={{
            '&.Mui-selected': {
              color: 'orange',
            },
            '&.MuiTab-root': {
              color: 'grey',
            },
          }}
        />
      </Tabs>
      {tabIndex === 0 && (
        <Table3
          selectedCountry={selectedCountry}
          onRowSelect={handleRowSelect}
          region={region} // Pass region prop here
        />
      )}
      {tabIndex === 1 && (
        <Table3
          selectedCountry={selectedCountry}
          onRowSelect={handleRowSelect}
          region={region} // Pass region prop here
        />
      )}
      {tabIndex === 2 && (
        <Table3
          selectedCountry={selectedCountry}
          onRowSelect={handleRowSelect}
          region={region} // Pass region prop here
        />
      )}
    </Grid>









        </Grid>
      </Box>
    </Box>
  );
}
