import React from 'react';
import { Typography, Divider, Box } from '@mui/material';
import Homecards from './homecards2'; // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme as useMuiTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Homelogo from '../components/Homelogo';
import Homeinfo from '../components/Homeinfos';




function Home() {

  const theme = useMuiTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));





  return (
    <Box sx={{ padding: 2 }}>

<Grid
       sx={{ 
        backgroundColor: '',
        borderRadius: '5px',
width: '100%'
         }}          
               
         item xs={16}>
<Homeinfo/>

 
         </Grid>






      <Typography variant="h6" sx={{ marginBottom: 1 }}>





      </Typography>

          <Homecards/>


<Box
sx={{ 
        display: 'flex', 
        flexDirection: isMobile ? 'column' : 'row', // Horizontal on mobile, vertical otherwise

        flexWrap: 'wrap', 
        justifyContent: 'center' }}> 
      <Grid 
       sx={{ 
        backgroundColor: '',
        mt: "10px",
        gap: "10px",
         }}
      container spacing={2} columns={16}>
 <Grid
        item
        xs={16}
        xl={8}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Homelogo />
      </Grid>





      </Grid>
</Box>
      


    </Box>
  );
}

export default Home;
