import * as React from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useCountry } from '../../Context/CountryContext'; // Adjust path as necessary

const size = {
  width: 500,
  height: 300, // Adjusted height for better label visibility
};

export default function Chart1({ region }) {
  const { countryData } = useCountry(); // Use context to get countryData
  
  // Filter country data based on the received region prop
  const filteredCountryData = region
    ? countryData.filter(country => country.region === region)
    : countryData;

  // Transform filtered data to match the format required by PieChart
  const filteredData = filteredCountryData.map((country) => ({
    value: country.gdp, // Use GDP as the value for the pie chart
    label: country.name, // Use country name as the label
  }));

  return (
    <Box
      sx={{
        position: 'relative', // Ensure relative positioning for the center label
        backgroundColor: '',
        borderRadius: '5px',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        padding: 1,
      }}
    >
      {/* Title */}
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 0 }}>
        GDP Share - {region.charAt(0).toUpperCase() + region.slice(1)}
      </Typography>

      {/* Subtitle */}
      <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>
        Regional GDP share
      </Typography>

      {/* Pie Chart */}
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <PieChart
          series={[
            {
              outerRadius: 100,
              innerRadius: 60,
              paddingAngle: 2,
              data: filteredData,
              type: 'pie',
              label: (location) => location.label, // Display country name on each slice
            },
          ]}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
              fontWeight: 'bold',
            },
          }}
          {...size}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '16px',
          }}
        >
        </Box>
      </Box>
    </Box>
  );
}
