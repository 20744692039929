import * as React from 'react';
import Box from '@mui/material/Box';


export default function Quizhome() {

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

    </Box>
  );
}
