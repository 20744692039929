import * as React from 'react';
import Box from '@mui/material/Box';
import Quiz from '../components/Quiz';
import Quiz2 from '../components/Quiz2';
import Quizzes from '../components/Quizzes';





export default function Data() {

  return (
    <Box sx={{ 
    display: 'flex', 
    flexDirection: 'column', 
    mt: '80px', 
    mb: '40px', 
    padding: '0px', 
    gap: '0px', 
    width: '100%', 
    backgroundColor: '', 
    alignItems: 'center', 
    justifyContent: 'center' 
    
    }}>
    
    {/* <Quiz/> */}
    <Quiz2/>
    {/* <Quizzes/> */}
    
    
    </Box>
  );
}
