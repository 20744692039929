import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Chart4() {
  return (
    <Box
      sx={{
        backgroundColor: '',
        borderRadius: '5px',
        boxShadow: 7,
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        padding: 1,
      }}
    >
      {/* Title */}
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 0 }}>
       Average Age - Asia
      </Typography>

      {/* Subtitle */}
      <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 0 }}>
        Demographics
      </Typography>

      <LineChart
        series={[
          {
            type: 'line',
            data: [1, 2, 3, 7, 4, 2, 3, 4, 5],
            valueFormatter: (v, i) => `${v}${i > 4 ? ' (estimated)' : ''}`,
            lineStyle: {
              stroke: 'blue',
            },
          },
          {
            type: 'line',
            data: [null, null, null, null, 1, 2, 3, 4, 5],
            lineStyle: {
              strokeDasharray: '5 5',
              stroke: 'red',
            },
          },
        ]}
        xAxis={[{ data: [0, 1, 2, 2, 4, 5, 6, 7, 8] }]}
        height={200}
        width={400}
      />
    </Box>
  );
}
