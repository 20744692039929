import React, { useState, useEffect } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import axios from 'axios';

export default function Maintable2() {
  const [currencyPairs, setCurrencyPairs] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    // Fetch data from the API
    axios.get('http://localhost:5000/api/currency-pairs')
      .then(response => setCurrencyPairs(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  // Function to calculate the change percentage
  const calculateChange = (buy, sell) => {
    return ((sell - buy) / buy * 100).toFixed(2);
  };

  // Function to determine the color based on change
  const getChangeColor = (change) => {
    if (change > 0) return 'green';
    if (change < 0) return 'red';
    return 'gray'; // Neutral
  };

  // Function to handle row selection
  const handleRowClick = (row) => {
    setSelectedRow(row.pair);
  };

  return (
    <Box
      sx={{
        backgroundColor: '',
        width: '100%',
        height: '100%',
        position: 'sticky',
        bottom: 0,
        left: 0,
        padding: '16px',
        border: '2px solid #fff',
        overflow: 'auto',
      }}
    >
      <Typography variant="h6" gutterBottom>
        Popular Currency Pairs
      </Typography>
      <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 100px)' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Currency Pair</TableCell>
              <TableCell>Change (%)</TableCell>
              <TableCell>Buy Price</TableCell>
              <TableCell>Sell Price</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currencyPairs.map((row) => {
              const change = calculateChange(row.buy, row.sell);
              return (
                <TableRow
                  key={row.pair}
                  onClick={() => handleRowClick(row)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#0d1821', // Light gray background on hover
                    },
                    backgroundColor: selectedRow === row.pair ? '#0d1821' : 'transparent', // Highlight selected row
                  }}
                >
                  <TableCell>{row.pair}</TableCell>
                  <TableCell sx={{ color: getChangeColor(change) }}>
                    {change}%
                  </TableCell>
                  <TableCell>{row.buy.toFixed(4)}</TableCell>
                  <TableCell>{row.sell.toFixed(4)}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="error" size="small" sx={{ marginRight: 1 }}>
                      Sell
                    </Button>
                    <Button variant="contained" color="success" size="small">
                      Buy
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
