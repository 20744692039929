import React from 'react';
import { Typography } from '@mui/material';

function About() {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        About Page
      </Typography>
      <Typography variant="body3">
        This is the About page of the Aeriweb App.
      </Typography>
    </div>
  );
}

export default About;
