// src/contexts/CountryContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

// Create Context
const CountryContext = createContext();

// Create a provider component
export function CountryProvider({ children }) {
  const [countryData, setCountryData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCountryData, setSelectedCountryData] = useState(null);

  useEffect(() => {
    // Function to fetch country data
    const fetchCountryData = async () => {
      try {
        const response = await axios.get('https://aeri-api.onrender.com/api/countries'); // Fetch from API
        // const response = await axios.get('http://localhost:5000/api/countries');
        setCountryData(response.data);
      } catch (error) {
        console.error('Error fetching country data:', error);
      }
    };

    // Fetch data on component mount
    fetchCountryData();
  }, []);

  // Handle country selection
  const handleCountryChange = (countryCode) => {
    const country = countryData.find(c => c.code === countryCode);
    setSelectedCountry(countryCode);
    setSelectedCountryData(country || null);
  };

  return (
    <CountryContext.Provider value={{ countryData, selectedCountry, selectedCountryData, handleCountryChange }}>
      {children}
    </CountryContext.Provider>
  );
}

// Create a custom hook to use the context
export const useCountry = () => useContext(CountryContext);
