import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Resizable } from 're-resizable';
import Chartbar from '../components/Chartbar';
import Maintable from '../components/Maintable';
import Maintable2 from '../components/Maintable2';
import Sidebarchart from '../components/Sidebarchart';

function Handelen({ onTradeClick }) {
  const [selectedTable, setSelectedTable] = useState('top10');

  const handleSelectionChange = (selection) => {
    setSelectedTable(selection);
  };

  const renderTable = () => {
    switch (selectedTable) {
      case 'top10':
        return <Maintable onTradeClick={onTradeClick} />;
      case 'popular':
        return <Maintable2 onTradeClick={onTradeClick} />;
      case 'exotic':
        return <Maintable2 onTradeClick={onTradeClick} />;
      case 'asia':
        return <Maintable2 onTradeClick={onTradeClick} />;
      case 'nlStocks':
        return <Maintable2 onTradeClick={onTradeClick} />;
      case 'euStocks':
        return <Maintable2 onTradeClick={onTradeClick} />;
      case 'londonStocks':
        return <Maintable2 onTradeClick={onTradeClick} />;
      case 'asiaStocks':
        return <Maintable2 onTradeClick={onTradeClick} />;
      default:
        return <Maintable onTradeClick={onTradeClick} />;
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {/* Main content */}
      <Grid container spacing={0} sx={{ flex: 1 }}>
        <Grid
          item xs={2}
          sx={{
            border: '2px solid #fff',
            backgroundColor: 'inherit',
            padding: 0,
            height: '100%',
            overflow: 'auto',
            boxSizing: 'border-box',
          }}
        >
          <Sidebarchart onSelectionChange={handleSelectionChange} />
        </Grid>

        <Grid
          item xs={10}
          sx={{
            backgroundColor: '',
            padding: 0,
            height: '100%',
            overflowY: 'auto',
          }}
        >
          {renderTable()}
        </Grid>
      </Grid>

      {/* Resizable Bottom Bar */}
      <Resizable
        defaultSize={{
          width: '100%',
          height: 300,
        }}
        minHeight={300}
        minWidth="100%"
        maxHeight={600}
        enable={{ top: true, right: false, bottom: false, left: false }}
        style={{
          backgroundColor: '',
          width: '100%',
          height: '100%',
          position: 'sticky',
          bottom: 0,
          left: 0,
          padding: '0px',
          border: '2px solid #fff',
        }}
      >
        <Grid
          container
          sx={{
            backgroundColor: '#0d1821',
            height: '100%',
            padding: 1,
          }}
        >
          <Grid item xs={12}>
            <Chartbar />
          </Grid>
        </Grid>
      </Resizable>

      {/* Drawer for trade details */}
    </Box>
  );
}

export default Handelen;
