import React, { createContext, useState, useContext } from 'react';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';

// Create a Context for the Theme
const ThemeContext = createContext();

const ThemeProviderComponent = ({ children }) => {
  const [mode, setMode] = useState('dark'); // Default theme mode

  // Define the theme based on the current mode
  const theme = createTheme({
    palette: {
      mode: mode,
      ...(mode === 'light'
        ? {
            // Light mode settings
            primary: {
              main: '#283039',
            },
            background: {
              default: '#e4e5f1',
              paper: '#ede8f5',  // Set the background color for cards and tables to orange

            },
            text: {
              primary: '#000',
            },
          }
        : {
            // Dark mode settings
            primary: {
              main: '#1d4a7d',
            },
            background: {
              default: '#0f1214',
              paper: '#000',  // Set the background color for cards and tables in dark mode

            },
            text: {
              primary: '#ffffff',
            },
          }),
    },
    typography: {
      fontFamily: 'Roboto, Arial, sans-serif',  // Set Roboto as the default font
      fontSize: 14, // Default font size
    },
    components: {
      // Define custom styles for the Sidebar
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: mode === 'light' ? '#dadde0' : '#14181b', // Hex color codes for light and dark modes
          },
        },
      },
      // Define custom styles for the Header
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'light' ? '#ede8f5' : '#fffff', // Hex color codes for light and dark modes
          },
        },
      },
    },
  });

  // Toggle the theme mode
  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeContext.Provider value={{ toggleTheme, mode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

const useTheme = () => useContext(ThemeContext);

export { ThemeProviderComponent, useTheme };
