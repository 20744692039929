import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box, CssBaseline, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles'; // Assuming ThemeProvider from @mui/material
import Button from '@mui/joy/Button'; // Example of Joy UI component
import Home from './pages/Home';
import About from './pages/About';
import Handelen from './pages/Handel';
import Dashboard from './pages/Dashboard';
import Data from './pages/Data';
import Analysis from './pages/Analysis';
import Reports from './pages/Reports';
import Login from './pages/Login';
import Register from './pages/Register';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import { ThemeProviderComponent } from './Theme/ThemeContext'; // Ensure this path is correct
import { CountryProvider } from './Context/CountryContext';

const drawerWidth = 250; // Adjust this for your sidebar width
const collapsedWidth = 80; // Adjust this for the collapsed sidebar width

function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <ThemeProviderComponent> {/* Wrap the entire app with ThemeProviderComponent */}
       <CountryProvider>
      <Router>
        <Box sx={{ display: 'flex', height: '100vh' }}>
          <CssBaseline />
          <Sidebar open={drawerOpen} toggleDrawer={toggleDrawer} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              transition: 'margin 0.3s ease',
              overflow: 'hidden',
              maxWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              zIndex: 1,
            }}
          >
            <Header toggleDrawer={toggleDrawer} />
            <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/handelen" element={<Handelen />} />
                <Route path="/data" element={<Data />} />
                <Route path="/analysis" element={<Analysis />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/Dashboard" element={<Dashboard />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
              </Routes>
            </Box>
            <Box sx={{ textAlign: 'center', padding: 2, backgroundColor: '#0d1821' }}>
              <Typography variant="body2" color="white">
                &copy; Aethos FinTech solutions | 2024 All rights reserved.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Router>     
      </CountryProvider>
    </ThemeProviderComponent>
  );
}

export default App;
