import React, { useEffect, useState } from 'react';
import axios from 'axios';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Grid from '@mui/material/Grid';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import Box from '@mui/material/Box';

const initialEconomicData = [
  { title: 'name', sectionRef: 5, subtitle: '.', icon: null, sparklineData: [81, 92, 70, 63, 92, 54, 72], chartType: 'barchart' },
  { title: 'GDP', sectionRef: 1, subtitle: '.', icon: <AttachMoneyIcon />, sparklineData: [10, 15, 14, 13, 15, 16, 20], chartType: 'sparkline' },
  { title: 'GDPGrowth', sectionRef: 0, subtitle: '.', icon: <TrendingUpIcon />, sparklineData: [3, 5, 2, 7, 6, 9, 5], chartType: 'sparkline' },
  { title: 'Inflation', sectionRef: 2, subtitle: '3.2%', icon: <PriceChangeIcon />, sparklineData: [2, 3, 4, 3, 3, 2, 4], chartType: 'sparkline' },
  { title: 'Interest', sectionRef: 4, subtitle: '4.25%', icon: <CreditScoreIcon />, sparklineData: [1.2, 4.7, 14.8, 15, 15.1], chartType: 'barchart' },
  // { title: 'Item A', sectionRef: 4, subtitle: '4.25%', icon: <CreditScoreIcon />, sparklineData: [1.2, 4.7, 14.8, 15, 15.1], chartType: 'barchart' },
  // { title: 'Item B', sectionRef: 4, subtitle: '4.25%', icon: <CreditScoreIcon />, sparklineData: [1.2, 4.7, 14.8, 15, 15.1], chartType: 'barchart' },
  { title: 'Unemployment', sectionRef: 3, subtitle: '5.1%', icon: <Diversity3Icon />, sparklineData: [6, 5, 6, 5, 5, 6, 4], chartType: 'barchart' },
];

const BasicInfo = ({ selectedCountryData, sectionRefs, scrollToSection }) => {
  const [economicData, setEconomicData] = useState(initialEconomicData);
  const [selectedRegion, setSelectedRegion] = useState('africa'); // Initial region
  const [selectedCountry, setSelectedCountry] = useState('');
  const [currentCountryData, setCurrentCountryData] = useState([]);

  // Fetch country data from MongoDB

  // Filter country data based on selected region
  useEffect(() => {
    const filteredCountries = currentCountryData.filter(country => country.region === selectedRegion);
    setCurrentCountryData(filteredCountries);

    if (filteredCountries.length > 0 && !selectedCountry) {
      setSelectedCountry(filteredCountries[0].code);
    }
  }, [selectedRegion, currentCountryData]);

  // Update economic data when selectedCountryData changes
  useEffect(() => {
    if (selectedCountryData) {
      setEconomicData(prevData =>
        prevData.map(item => {
          if (item.title === 'GDP') {
            return { ...item, subtitle: selectedCountryData.gdp ? `${selectedCountryData.gdp.toLocaleString('de-DE')} billion` : 'N/A' };
          }
          if (item.title === 'info') {
            return { ...item, subtitle: selectedCountryData.info ? `${selectedCountryData.info.toLocaleString('de-DE')}` : 'N/A' };
          }
          if (item.title === 'GDPGrowth') {
            return { ...item, subtitle: selectedCountryData.gdpGrowth ? `${selectedCountryData.gdpGrowth.toLocaleString('en-US')}%` : 'N/A' };
          }
          if (item.title === 'Inflation') {
            return { ...item, subtitle: selectedCountryData.inflation !== undefined ? `${selectedCountryData.inflation}%` : 'N/A' };
          }
          if (item.title === 'Interest') {
            return { ...item, subtitle: selectedCountryData.interest !== undefined ? `${selectedCountryData.interest}%` : 'N/A' };
          }
          if (item.title === 'Unemployment') {
            return { ...item, subtitle: selectedCountryData.jobless !== undefined ? `${selectedCountryData.jobless}%` : 'N/A' };
          }
          if (item.title === 'name') {
            return {
              ...item,
              subtitle: selectedCountryData.name || 'Unknown Country',
              icon: selectedCountryData.code ? (
                <img
                  src={`https://flagcdn.com/${selectedCountryData.code.toLowerCase()}.svg`}
                  alt={`${selectedCountryData.name} flag`}
                  style={{ width: 30, height: 20, borderRadius: '3px' }}
                />
              ) : (
                <TrendingUpIcon />
              ),
            };
          }
          return item;
        })
      );
    }
  }, [selectedCountryData]);

  return (
    <Box>
      <List sx={{ width: '100%', height: '60%', bgcolor: 'inherit' }}>
        <Grid container spacing={0}>
          {economicData.map((item, index) => (
            <Grid
              sx={{
                backgroundColor: '',
                borderRadius: '5px',
                padding: '5px',
              }}
              item
              xs={6}
              sm={6}
              md={4}
              xl={5}
              key={index}
            >
              <ListItem
                component="div"
                onClick={() => scrollToSection(sectionRefs[item.sectionRef])}
                sx={{
                  cursor: 'pointer',
                  backgroundColor: 'background.paper',
                  borderRadius: '5px',
                  boxShadow: 7,
                  position: 'relative',
                  overflow: 'hidden',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-5px) scale(1.03)',
                    boxShadow: 12,
                  },
                }}
              >
                <ListItemAvatar sx={{ zIndex: 2 }}>
                  {item.icon}
                </ListItemAvatar>
                <ListItemText
                  primary={item.subtitle}
                  secondary={item.title}
                  primaryTypographyProps={{ sx: { fontWeight: 'bold', fontSize: '1.2em' } }}
                  secondaryTypographyProps={{ sx: { color: 'text.secondary' } }}
                  sx={{ zIndex: 2 }}
                />
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </List>
    </Box>
  );
};

export default BasicInfo;
