import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Avatar from '@mui/material/Avatar';
import AssignmentIcon from '@mui/icons-material/Assignment';
import InsightsIcon from '@mui/icons-material/Insights';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import Typography from '@mui/material/Typography';

export default function LinearIndeterminate(props) {
  return (
    <Box sx={{ width: '100%', 
    borderRadius: '5px',
    maxWidth: '800px', bgcolor: 'primary.main',
 }}>
<Stack 
  direction="row" 
  spacing={2} 
  padding={1} 
  alignItems="center" 
  justifyContent="center" 
  sx={{ width: '100%' }}
>
  <Typography sx={{ fontSize: 18, color: 'white' }} variant="h4" gutterBottom>
    
  </Typography>
</Stack>

<Box
  sx={{
    height: '100px',
    bgcolor: 'primary.main',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    paddingX: 2, // Added padding for better spacing on smaller screens
  }}
>
  <Stack direction="row" spacing={2} alignItems="center">
    {/* First Icon and Label */}
    <Stack direction="column" spacing={1} alignItems="center" justifyContent="center">
      <Avatar sx={{ bgcolor: 'gray' }} variant="rounded">
        <AssignmentIcon />
      </Avatar>
      <Typography sx={{ fontSize: 10, color: 'white' }} variant="overline" gutterBottom>
        Data
      </Typography>
    </Stack>

    {/* Progress Bar */}
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <LinearProgress
        sx={{
          width: { xs: '80px', sm: '150px', md: '200px' }, // Responsive width
          height: '2px',
        }}
        {...props}
      />
    </Box>

    {/* Second Icon and Label */}
    <Stack direction="column" spacing={1} alignItems="center" justifyContent="center">
      <Avatar sx={{ bgcolor: 'blue' }} variant="rounded">
        <StorageRoundedIcon />
      </Avatar>
      <Typography sx={{ fontSize: 10, color: 'white' }} variant="overline" gutterBottom>
        Analysis
      </Typography>
    </Stack>

    {/* Progress Bar */}
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <LinearProgress
        sx={{
          width: { xs: '80px', sm: '150px', md: '200px' }, // Responsive width
          height: '2px',
        }}
        {...props}
      />
    </Box>

    {/* Third Icon and Label */}
    <Stack direction="column" spacing={1} alignItems="center" justifyContent="center">
      <Avatar sx={{ bgcolor: 'gray' }} variant="rounded">
        <InsightsIcon />
      </Avatar>
      <Typography sx={{ fontSize: 10, color: 'white' }} variant="overline" gutterBottom>
        Insights
      </Typography>
    </Stack>
  </Stack>
</Box>
    </Box>
  );
}
