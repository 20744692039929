import React, { useState } from 'react';
import { Typography, TextField, Button, Box, Paper, Link, Alert, MenuItem, Select, InputLabel, FormControl, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Generate year options from 1900 to current year
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1900 + 1 }, (_, i) => currentYear - i);

  const handleRegister = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    const age = currentYear - birthYear; // Calculate age from birth year
    setLoading(true); // Start loading

    try {
      await axios.post('http://localhost:5000/api/register', {
        email,
        password,
        name,
        age
      });
      setSuccess('Registration successful!');
      setTimeout(() => {
        navigate('/login'); // Redirect to login page after successful registration
      }, 2000);
    } catch (error) {
      setError('Error registering user');
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <Box
      component={Paper}
      sx={{
        padding: 3,
        maxWidth: 400,
        margin: 'auto',
        marginTop: 5,
        boxShadow: 5,
        textAlign: 'center',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Register
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      {loading && <CircularProgress />}
      <Box
        component="form"
        onSubmit={handleRegister}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <TextField
          label="Name"
          variant="outlined"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        /> 
{/* CHANGE @ sign to required */}
        <TextField
          label="Email"
          variant="outlined"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          label="Confirm Password"
          type="password"
          variant="outlined"
          required
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <FormControl variant="outlined" required>
          <InputLabel>Birth Year</InputLabel>
          <Select
            value={birthYear}
            onChange={(e) => setBirthYear(e.target.value)}
            label="Birth Year"
            sx={{ maxHeight: '150px', overflowY: 'auto' }} // Limit height and enable scrolling
          >
            {years.map(year => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button type="submit" variant="contained" color="primary">
          Register
        </Button>
        <Link href="/login" variant="body2" sx={{ marginTop: 2 }}>
          Already have an account? Login
        </Link>
      </Box>
    </Box>
  );
}

export default Register;
