import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';

import Handelen from '../pages/Handelen';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  zIndex: theme.zIndex.drawer + 1, // Ensure AppBar is above Drawer
  position: 'relative', // Ensures AppBar stays in the flow with content
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin',  {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0, // Removed negative margin to avoid shifting content
    position: 'relative',
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  zIndex: theme.zIndex.drawer, // Ensure Drawer is below AppBar
}));

export default function Handel() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [tradeData, setTradeData] = React.useState(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setTradeData(null);
  };

  const handleOpenDrawer = (data) => {
    setTradeData(data);
    setOpen(true);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <AppBar position="relative">
          <Toolbar>
            <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
              Trade
            </Typography>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              sx={{ ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ flexGrow: 1, marginTop: '0px' }}> {/* Adjust marginTop to accommodate AppBar */}
          <Main 

          
          open={open}>
            <DrawerHeader />
            <Handelen onTradeClick={handleOpenDrawer} />
          </Main>
        </Box>
      </Box>
      <StyledDrawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            top: '64px', // Position the Drawer below the AppBar
            height: 'calc(100% - 0px)', // Ensure Drawer height fits the remaining space below the AppBar
          },
        }}
        variant="persistent" // Set Drawer to temporary for responsive behavior
        anchor="right"
        open={open}
        onClose={handleDrawerClose} // Ensure Drawer can be closed
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Box sx={{ padding: 2 }}>
          {tradeData && (
            <Box>
              <Typography variant="h6">Trade Details</Typography>
              <Typography variant="body1">Currency Pair: {tradeData.currencyPair}</Typography>
              <Typography variant="body1">Price: {tradeData.price}</Typography>
              <Typography variant="body1">Type: {tradeData.type}</Typography>
            </Box>
          )}
        </Box>
      </StyledDrawer>
    </Box>
  );
}
