import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, Box, Paper, Link, Alert, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const savedEmail = localStorage.getItem('savedEmail');
    const savedPassword = localStorage.getItem('savedPassword');
    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true);
    } else {
      setEmail('a@b');
      setPassword('demo');
    }
  }, []);

  const handleLogin = (event) => {
    event.preventDefault();
    
    axios.post('http://localhost:5000/api/login', { email, password })
      .then(response => {
        const token = response.data.token;
        localStorage.setItem('authToken', token); // Store token in local storage
        console.log('JWT Token:', token); // Log the token to the console

        if (rememberMe) {
          localStorage.setItem('savedEmail', email);
          localStorage.setItem('savedPassword', password);
        } else {
          localStorage.removeItem('savedEmail');
          localStorage.removeItem('savedPassword');
        }

        setSuccess('Login successful!');
        setTimeout(() => {
          navigate('/'); // Redirect to home or dashboard after login
          window.location.reload(); // Reload to force components to update
        }, 2000);
      })
      .catch(error => {
        setError('Invalid email or password');
      });
  };

  return (
    <Box
      component={Paper}
      sx={{
        padding: 3,
        maxWidth: 400,
        margin: 'auto',
        marginTop: 5,
        boxShadow: 5,
        textAlign: 'center',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      <Box
        component="form"
        onSubmit={handleLogin}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <TextField
          label="Email"
          variant="outlined"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              name="rememberMe"
              color="primary"
            />
          }
          label="Remember Me"
        />
        <Button type="submit" variant="contained" color="primary">
          Login
        </Button>
        <Link href="/register" variant="body2" sx={{ marginTop: 2 }}>
          Don't have an account? Register
        </Link>
      </Box>
    </Box>
  );
}

export default Login;
