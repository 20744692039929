import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import TableSortLabel from '@mui/material/TableSortLabel';
import { green, red } from '@mui/material/colors';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { styled } from '@mui/material/styles';
import { useCountry } from '../../Context/CountryContext'; // Adjust path as necessary

const columns = [
  { id: 'name', label: 'Country', minWidth: 50 },
  { id: 'gdp', label: 'GDP', minWidth: 50, align: 'right', format: (value) => value.toLocaleString('en-US') },
  { id: 'gdpGrowth', label: 'Growth (%)', minWidth: 50, align: 'right', format: (value) => value.toFixed(2) },
  { id: 'interest', label: 'Interest (%)', minWidth: 50, align: 'right', format: (value) => value.toFixed(2) },
  { id: 'inflation', label: 'Inflation (%)', minWidth: 50, align: 'right', format: (value) => value.toFixed(2) },
  { id: 'jobless', label: 'Jobless (%)', minWidth: 50, align: 'right', format: (value) => value.toFixed(1) },
];

const StyledTableRow = styled(TableRow)(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.action.selected : 'inherit',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.action.hover,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Table3({ selectedCountry, onRowSelect, region }) {
  const { countryData } = useCountry(); // Use context to get countryData
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');

  // Filter country data based on region
  const filteredCountryData = region
    ? countryData.filter(country => country.region === region)
    : countryData;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleRowClick = (country) => {
    onRowSelect(country);
  };

  return (
    <Paper>
      <TableContainer sx={{ width: '100%', maxHeight: 440 }}>
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{ padding: '8px 8px' }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(filteredCountryData, getComparator(order, orderBy)).map((row) => (
              <StyledTableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.code}
                onClick={() => handleRowClick(row)}
                selected={row.code === selectedCountry}
              >
                <TableCell>
                  <Chip
                    variant="outlined"
                    clickable
                    avatar={<Avatar alt="flag" src={`https://flagcdn.com/w40/${row.code.toLowerCase()}.png`} />}
                    label={row.name}
                  />
                </TableCell>
                <TableCell align="right">{row.gdp.toLocaleString('en-US')}</TableCell>
                <TableCell align="right">
                  {row.gdpGrowth.toFixed(1)}%
                  {row.gdpGrowth > 0 ? (
                    <ArrowUpwardIcon sx={{ color: green[500], verticalAlign: 'middle', fontSize: 'medium' }} />
                  ) : (
                    <ArrowDownwardIcon sx={{ color: red[500], verticalAlign: 'middle', fontSize: 'medium' }} />
                  )}
                </TableCell>
                <TableCell align="right">{row.interest.toFixed(2)}</TableCell>
                <TableCell align="right">{row.inflation.toFixed(0)}</TableCell>
                <TableCell align="right">{row.jobless.toFixed(1)}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
