import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, Avatar, Menu, MenuItem, ListItemIcon, Divider, Tooltip, Switch, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useTheme } from '../Theme/ThemeContext'; // Import the theme context
import axios from 'axios';
import aerilogo1 from '../assets/aerilogo3.png'; // Ensure this path is correct
import aerilogo2 from '../assets/aerilogo1.png'; // Ensure this path is correct
import { Link } from 'react-router-dom'; // Ensure this import is present



function Header({ toggleDrawer }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null); // Store user information
  const open = Boolean(anchorEl);
  const { toggleTheme, mode } = useTheme(); // Get the theme toggle function and current mode

  
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    console.log('JWT Token from Header:', token); // Log the token to the console

    if (token) {
      setIsLoggedIn(true);
      // Fetch the current user information
      axios.get('http://localhost:5000/api/current_user', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        setUser(response.data);
      })
      .catch(error => {
        console.error('Error fetching user information:', error);
        setIsLoggedIn(false);
        setUser(null);
      });
    } else {
      setIsLoggedIn(false);
      setUser(null);
    }
  }, []); // Empty dependency array means this effect runs once when the component mounts

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    window.location.href = '/login'; // Redirect to login page
  };

  const handleLogout = () => {
    // Simulating logout - clear token from localStorage
    localStorage.removeItem('authToken');
    setIsLoggedIn(false);
    setUser(null);
  };

  return (
    <AppBar 
          sx={{ 
              backgroundColor: '' }}
    
    position="static">
      <Toolbar>
        {/* Typography with text "Appbar" aligned to the left */}
        <Box sx={{ 
              backgroundColor: '',

          flexGrow: 1 }}>
        
        
        <Box
      sx={{
        backgroundColor: '',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
        padding: '16px 0',
      }}
    >
      <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
        <img 
          src={mode === 'light' ? aerilogo2 : aerilogo1} 
          alt="Aeri Logo" 
          style={{ 
            width: '70px', 
            transition: 'width 0.3s' 
          }} 
        />
      </Link>
    </Box>


        </Box>

        {/* Right aligned items */}
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
          
          
          <IconButton
            sx={{
              color: mode === 'dark' ? '#fff' : '#444',
              borderRadius: '0%',
              padding: '0px',
            }} 
            onClick={toggleTheme}
          >
            <Switch
              checked={mode === 'dark'}
              icon={<LightModeIcon sx={{ color: mode === 'dark' ? '#fff' : '#444' }} />}
              checkedIcon={<DarkModeIcon sx={{ color: mode === 'dark' ? '#fff' : '#444' }} />}
              sx={{
                '& .MuiSwitch-switchBase': {
                  padding: 0,
                  margin: 1,
                  transform: 'translateX(2px)',
                },
                '& .MuiSwitch-thumb': {
                  boxSizing: 'border-box',
                  width: 4,
                  height: 4,
                },
                '& .MuiSwitch-track': {
                  borderRadius: 26 / 2,
                  backgroundColor: mode === 'dark' ? '#333' : '#ccc',
                  opacity: 1,
                },
                '& .MuiSwitch-switchBase.Mui-checked': {
                  transform: 'translateX(20px)',
                  color: '#fff',
                },
              }}
            />
          </IconButton>

          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', ml: 2 }}>
            {isLoggedIn ? (
              <>
                <Tooltip title="Account">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Avatar sx={{ width: 32, height: 32 }}>
                      {user?.email[0].toUpperCase() || 'U'}
                    </Avatar>
                  </IconButton>
                </Tooltip>

                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={handleClose}>
                    <Avatar /> Mijn profiel
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Avatar /> Mijn account
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <PersonAdd fontSize="small" />
                    </ListItemIcon>
                    Vriend uitnodigen
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    Instellingen
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Uitloggen
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Button 
                sx={{ display: { xs: 'none', sm: 'flex' }, color: 'inherit' }} // Show only on small screens
                color="inherit" 
                onClick={handleLogin}
              >
                Login
              </Button>
            )}
          </Box>

        <IconButton
          sx={{ 
            display: { xs: 'flex', sm: 'none' }, 
            color: theme => theme.palette.mode === 'light' ? 'black' : 'inherit' 
          }} // Show only on small screens
          edge="start"
          aria-label="menu"
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>

        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
