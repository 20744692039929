import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Badge from '@mui/material/Badge';
import ArticleIcon from '@mui/icons-material/Article';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme as useMuiTheme } from '@mui/material';
import Regionchart from '../components/Regionchart';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Avatar from '@mui/material/Avatar';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// Define constants for multiple cards
const cardData = [
  {
    title: "Asia",
    countries: '44',
    description: `Stabile growth over 10 year period`,
    gdpData: [1, 4, 2, 5, 5, 2, 4, 7], // GDP growth data
    inflationData: [2, 2, 2, 2, 2, 2, 2, 2], // GDP growth data
    link: 'asia'
  },
  {
    title: "Africa",
    countries: 54,

    description: `Economic Data`,
    gdpData: [2, 3, 5, 4, 6, 2, 3, 1], // GDP growth data
    inflationData: [2, 3, 5, 4, 6, 2, 3, 1], // GDP growth data
    link: 'africa'
  },
  {
    title: "America",
    countries: 54,

    description: `Economic Data`,
    gdpData: [3, 5, 2, 6, 4, 3, 5, 1], // GDP growth data
    inflationData: [3, 5, 2, 6, 4, 3, 5, 1], // GDP growth data
    link: 'america'
  },
  {
    title: "Europe",
    countries: 32,

    description: `Economic Data`,
    gdpData: [4, 6, 3, 5, 2, 4, 6, 8], // GDP growth data
    inflationData: [4, 6, 3, 5, 2, 4, 6, 8], // GDP growth data
    link: 'europe'
  },
];


const ActionAreaCard = ({ title, countries, description, gdpData, inflationData, link }) => {
  const navigate = useNavigate();

  const handleDashboardClick = () => {
    navigate(`/dashboard`);
  };

  const theme = useMuiTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [view, setView] = React.useState('list');
  const [alignment, setAlignment] = React.useState('web');
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };


  return (
    <Box sx={{ flexGrow: 1, marginBottom: 2 }}>
    <Card 
      sx={{
        direction: isMobile ? 'row' : 'column',
        minWidth: 350, 
        marginRight: isMobile ? '0px' : '10px',

         
      }}>
      <CardActionArea>
        <Stack
          direction="row"
          gap="10px"
          alignItems="center"
          alignContent="center"
          justifyItems="center"
          justifyContent="center"
        > 
          {/* <Avatar sx={{ bgcolor: 'lightblue' }} variant="square" /> */}
          <Typography 
            gutterBottom 
            variant="button" 
            sx={{ 
              mt: (2),
              textAlign: 'center',
               fontSize: '1.0rem' }}  
          >
            {title}
          </Typography>
        </Stack>

        <CardContent>
          {/* <Regionchart gdpData={gdpData} inflationData={inflationData}/>  */}

          <Typography gutterBottom variant="button" component="div">
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {/* {description} */}
            <Box sx={{ backgroundColor: '', minWidth: 150, marginTop: 1 }}>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
                justifyContent="center"
                sx={{ padding: '0 16px' }}
              >


              </Stack>
            </Box>
            <Box sx={{ backgroundColor: '', minWidth: 150, marginTop: 2 }}>
              <Divider>
              <Stack
                justifyContent="center"
                sx={{ padding: '0 16px' }}
                direction="row" spacing={1}
              >
                <Chip label={countries} size="small" />
                </Stack>
              </Divider>
              
            </Box>
            <Box sx={{ backgroundColor: '', minWidth: 150, marginTop: 2 }}>
              <Stack
                justifyContent="center"
                sx={{ padding: '0 16px' }}
                direction="row" spacing={1}
              >
                
                <Button 
                  variant="contained" 
                  endIcon={<KeyboardDoubleArrowRightIcon />} 
                  onClick={handleDashboardClick}
                >
                 dashboard
                </Button>
              </Stack>
            </Box>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  </Box>
  );
};

export default ActionAreaCard;
export { cardData };
