import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';

const ListItemBox = ({ text, icon, onClick }) => (
  <ListItem disablePadding>
    <ListItemButton onClick={onClick}>
      {/* {icon && <ListItemIcon>{icon}</ListItemIcon>} */}
      <ListItemText primary={text} />
    </ListItemButton>
  </ListItem>
);

export default function Sidebarchart({ onSelectionChange }) {
  return (
    <Box sx={{ minHeight: 452, minWidth: 0 }}>
      <Accordion
        expanded={true} // Open by default
        sx={{ boxShadow: 'none', border: 'none', marginBottom: '0px' }} // Remove default shadow and margin
      >
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ padding: '0px 16px' }} // Adjust padding to reduce space
        >
          <Typography>Forex</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ padding: '0px 16px' }} // Adjust padding to reduce space
        >
          <Typography>
            <nav aria-label="main mailbox folders">
              <List>
                <ListItemBox
                  text="Top 10"
                  icon={<InboxIcon />}
                  onClick={() => onSelectionChange('top10')}
                />
                <ListItemBox
                  text="Popular"
                  icon={<InboxIcon />}
                  onClick={() => onSelectionChange('popular')}
                />
                <ListItemBox
                  text="Exotic"
                  icon={<DraftsIcon />}
                  onClick={() => onSelectionChange('exotic')}
                />
                <ListItemBox
                  text="Asia"
                  icon={<DraftsIcon />}
                  onClick={() => onSelectionChange('asia')}
                />
              </List>
            </nav>
            <Divider sx={{ margin: '8px 0' }} /> {/* Reduce the margin around the divider */}
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Category */}
      <Accordion
        expanded={true} // Open by default
        sx={{ boxShadow: 'none', border: 'none', marginBottom: '0px' }} // Remove default shadow and margin
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{ padding: '0px 16px' }} // Adjust padding to reduce space
        >
          <Typography>Aandelen</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ padding: '8px 16px' }} // Adjust padding to reduce space
        >
          <Typography>
            <nav aria-label="main mailbox folders">
              <List>
                <ListItemBox
                  text="NL"
                  icon={<InboxIcon />}
                  onClick={() => onSelectionChange('nlStocks')}
                />
                <ListItemBox
                  text="Europa"
                  icon={<InboxIcon />}
                  onClick={() => onSelectionChange('euStocks')}
                />
                <ListItemBox
                  text="London"
                  icon={<DraftsIcon />}
                  onClick={() => onSelectionChange('londonStocks')}
                />
                <ListItemBox
                  text="Asia"
                  icon={<DraftsIcon />}
                  onClick={() => onSelectionChange('asiaStocks')}
                />
              </List>
            </nav>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
