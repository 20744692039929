import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

function createData(question, correctAnswer, userAnswer, points) {
  return {
    question,
    correctAnswer,
    userAnswer,
    points,
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.question}
        </TableCell>
        <TableCell align="right">{row.correctAnswer}</TableCell>
        <TableCell align="right">
          {row.userAnswer}
          {row.userAnswer === row.correctAnswer ? (
            <CheckCircleIcon sx={{ color: 'green', ml: 1 }} />
          ) : (
            <CancelIcon sx={{ color: 'red', ml: 1 }} />
          )}
        </TableCell>
        <TableCell align="right">{row.points}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
.
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    question: PropTypes.string.isRequired,
    correctAnswer: PropTypes.string.isRequired,
    userAnswer: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired,
  }).isRequired,
};

export default function Results({ questions = [], userAnswers = [] }) {
  // Calculate total score and percentage
  const totalPoints = questions.length;
  const scoredPoints = questions.reduce((score, question, index) => {
    const userAnswer = userAnswers[index] || '';
    return userAnswer === question.answer ? score + 1 : score;
  }, 0);
  const percentage = (scoredPoints / totalPoints) * 100;

  const rows = questions.map((question, index) => {
    const userAnswer = userAnswers[index] || '';
    const points = userAnswer === question.answer ? 1 : 0;
    return createData(question.question, question.answer, userAnswer, points);
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
      <TableContainer component={Paper} sx={{ width: '100%' }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Question</TableCell>
              <TableCell align="right">Correct Answer</TableCell>
              <TableCell align="right">Your Answer</TableCell>
              <TableCell align="right">Points</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={row.question} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          mt: 4,
          padding: '16px',
          border: '2px solid black',
          borderRadius: '8px',
          textAlign: 'center',
          width: '200px',
          backgroundColor: '#f0f0f0',
        }}
      >
        <Typography variant="h5">Total Score</Typography>
        <Typography variant="h3" fontWeight="bold" sx={{ color: 'primary.main' }}>
          {scoredPoints}/{totalPoints}
        </Typography>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          ({percentage.toFixed(1)}%)
        </Typography>
      </Box>
    </Box>
  );
}

Results.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.string).isRequired,
      answer: PropTypes.string.isRequired,
    })
  ).isRequired,
  userAnswers: PropTypes.arrayOf(PropTypes.string).isRequired,
};
