import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material'; // Import Button
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import axios from 'axios';

// Helper function to calculate time ago
function calculateTimeAgo(dateString) {
  const currentDate = new Date();
  const targetDate = new Date(dateString);

  const differenceInMillis = currentDate.getTime() - targetDate.getTime();
  const differenceInHours = Math.floor(differenceInMillis / (1000 * 60 * 60));
  const differenceInDays = Math.floor(differenceInHours / 24);

  if (differenceInDays > 0) {
    return `${differenceInDays} days`;
  } else if (differenceInHours > 0) {
    return `${differenceInHours} hours`;
  } else {
    return 'Just now';
  }
}

export default function AlignItemsList() {
  const [vacatures, setVacatures] = useState([]);
  const [selectedVacature, setSelectedVacature] = useState(null); // Default is null until data is fetched
  const [loading, setLoading] = useState(true); // New loading state
  const [savedVacatures, setSavedVacatures] = useState([]); // State to hold saved vacatures
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [openSnackbar, setOpenSnackbar] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');


const toggleSaveVacature = async (vacatureidnumber) => {
  const token = localStorage.getItem('authToken');

  if (!token) {
    console.error('User is not authenticated');
    return;
  }

  try {
    const response = await axios.post('http://localhost:5000/api/toggle-save-vacature', 
      { 
        vacatureidnumber,
        date: new Date().toISOString(), // Save the current date and time
      }, 
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (response.status === 200) {
      let message = '';
      // Update saved vacatures list
      if (savedVacatures.some(v => v.vacatureidnumber === vacatureidnumber)) {
        // Remove from list
        setSavedVacatures(savedVacatures.filter(v => v.vacatureidnumber !== vacatureidnumber));
        message = 'Vacature removed from saved list';
      } else {
        // Add to list
        setSavedVacatures([...savedVacatures, { vacatureidnumber, date: new Date().toISOString() }]);
        message = 'Vacature saved successfully';
      }

      // Show Snackbar with success message
      setSnackbarMessage(message);
      setOpenSnackbar(true);
    }
  } catch (error) {
    console.error('Failed to toggle vacature:', error);
    setSnackbarMessage('Failed to save vacature');
    setOpenSnackbar(true);
  }
};

const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpenSnackbar(false);
};





  const fetchSavedVacatures = async () => {
    const token = localStorage.getItem('authToken');

    if (!token) {
      console.error('User is not authenticated');
      return;
    }

    try {
      const response = await axios.get('http://localhost:5000/api/saved-vacatures', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSavedVacatures(response.data);
    } catch (error) {
      console.error('Failed to fetch saved vacatures:', error);
    }
  };

  const handleVacatureClick = (item) => {
    setSelectedVacature(item); // Set the selected vacature when a list item is clicked
  };

  useEffect(() => {
    axios.get('http://localhost:5000/api/vacatures')
      .then((response) => {
        setVacatures(response.data);
        setSelectedVacature(response.data[0]); // Set the first vacature as the default selected item
        setLoading(false); // Stop loading once data is fetched
      })
      .catch((error) => {
        console.error('Error fetching vacatures:', error);
        setLoading(false); // Stop loading even if there's an error
      });

    fetchSavedVacatures(); // Fetch saved vacatures on component mount
  }, []);

  const isVacatureSaved = (vacatureidnumber) => {
    return savedVacatures.some(v => v.vacatureidnumber === vacatureidnumber);
  };

  return (
    <Box>
      <Grid container spacing={1} sx={{ minHeight: '100vh' }}>
        {/* Main Grid Item for List */}
        <Grid item xs={12} md={4}>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {loading ? (
              <Typography sx={{ textAlign: 'center', marginTop: 2 }}>Loading...</Typography>
            ) : (
              vacatures.map((item) => (
                <React.Fragment key={item._id.$oid}>
                  <ListItem
                    alignItems="flex-start"
                    sx={{
                      position: 'relative',
                      bgcolor: selectedVacature?._id?.$oid === item._id?.$oid 
                        ? (isDarkMode ? 'rgba(0, 0, 0, 0.4)' : 'lightblue') // Highlighted color for selected item
                        : 'transparent',
                      '&:hover': {
                        bgcolor: selectedVacature?._id?.$oid === item._id?.$oid
                          ? (isDarkMode ? 'rgba(0, 0, 0, 0.4)' : 'lightblue') // Keep highlighted color on hover if selected
                          : (isDarkMode ? 'rgba(255, 255, 255, 0.08)' : 'lightgray'),
                      },
                      cursor: 'pointer',
                      borderRadius: 1, // Optional: Adds rounded corners for a smoother highlight effect
                    }}
                    onClick={() => handleVacatureClick(item)}
                    selected={selectedVacature?._id?.$oid === item._id?.$oid}
                  >
                    <ListItemAvatar>
                      <Avatar variant="rounded" alt={item.company} src="/static/images/avatar/1.jpg" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.title}
                      secondary={
                        <Stack spacing={0.5}>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {item.company}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {item.place}, {item.province}
                          </Typography>
                          <Typography variant="body2" sx={{ mt: 0, mb: 0 }}>
                            {calculateTimeAgo(item.date)}
                          </Typography>
                        </Stack>
                      }
                    />
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                      }}
                      aria-label="save"
                      onClick={() => toggleSaveVacature(item.idnumber)}
                    >
                      {isVacatureSaved(item.idnumber) ? <TurnedInIcon /> : <TurnedInNotIcon />}
                    </IconButton>
                  </ListItem>
                  {vacatures.indexOf(item) < vacatures.length - 1 && (
                    <Divider variant="inset" component="li" />
                  )}
                </React.Fragment>
              ))
            )}
          </List>
        </Grid>

        {/* Additional Info for Selected Vacature */}
        <Grid item xs={12} md={8}>
          {!loading && selectedVacature ? (
            <Box sx={{ padding: 2, bgcolor: 'background.paper', minHeight: '100vh' }}>
            <Stack direction='row' alignItems='center' sx={{ mb: 1 }}>
  <Typography variant="h6">{selectedVacature.title}  </Typography>
  
  {/* Save Vacature Button */}
  <Button
    variant="contained"
    color={isVacatureSaved(selectedVacature.idnumber) ? 'success' : 'primary'}
    sx={{ ml: 2 }}
    onClick={() => toggleSaveVacature(selectedVacature.idnumber)}
  >
    {isVacatureSaved(selectedVacature.idnumber) ? 'Saved' : 'Save'}
  </Button>
  {/* Snackbar for Save Action Feedback */}
  <Snackbar
      open={openSnackbar}
      autoHideDuration={3000}
      onClose={handleCloseSnackbar}
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity="success"
        variant="filled"
        sx={{ width: '100%' }}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
</Stack>

<Stack direction='row' alignItems='center' sx={{ mb: 1 }}>
  <Typography variant="button" color="gray">{selectedVacature.place} - {selectedVacature.province} </Typography>
  
</Stack>
<Divider sx={{ mb: 1 }}/>

              <Typography variant="body2" color="text.secondary">
                {selectedVacature.info}
              </Typography>
              
              
            </Box>
          ) : (
            <Box sx={{ padding: 2, bgcolor: 'background.paper', minHeight: '100vh' }}>
              <Typography sx={{ textAlign: 'center', marginTop: 2 }}>
                {loading ? 'Loading additional info...' : 'No vacature selected'}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
