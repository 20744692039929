import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import DataArrayIcon from '@mui/icons-material/DataArray';

// Define the data array with titles and image URLs
const economicItems = [
  { title: ' ', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtFu2T_IEccARnR1ftOPCi1V0CvdFo1-x97w&s' },
  { title: '', imageUrl: 'https://www.shareicon.net/data/256x256/2016/06/23/614099_excel_512x512.png' },
  { title: ' ', imageUrl: 'https://cdn.iconscout.com/icon/free/png-256/free-react-1-282599.png?f=webp&w=256' },
  { title: ' ', imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmf3B7Jav04Ewa9T721JLkX1Uejw9wi-IkQw&s' },
  { title: ' ', imageUrl: 'https://static-00.iconduck.com/assets.00/sql-database-generic-icon-380x512-ez505zus.png' },
];

// Styling for each item
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '' : '',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '0px',
  justifyContent: 'center',
  width: '100%',
}));

export default function DividerStack() {
  return (
    <Box sx={{ width: '100%', padding: 0 }}>


        <Stack
          direction="row"
          spacing={0}
          sx={{ width: '100%' }}
        >
          {economicItems.map((item, index) => (
            <Item key={index}>
              <img src={item.imageUrl} alt={item.title} width={35} height={35} />
            </Item>
          ))}
        </Stack>

    </Box>
  );
}
