// Setup multiple quiz data sets in an organized structure

export const quizDataSets = {
    quiz1: [
      {
        question: 'Which of the following is a definite article in Beng?',
        options: ['a', 'an', 'the', 'some'],
        answer: 'the',
      },
      {
        question: 'What is the past tense of "go"?',
        options: ['goed', 'going', 'went', 'gone'],
        answer: 'went',
      },
      {
        question: 'Which of the following is an adjective?',
        options: ['run', 'happily', 'beautiful', 'quickly'],
        answer: 'beautiful',
      },
    ],
  
    englishhistory: [
      {
        question: 'Which of the following is a definite article in English?',
        options: ['a', 'an', 'the', 'some'],
        answer: 'the',
      },
      {
        question: 'Who was the first monarch of the Tudor dynasty?',
        options: ['Henry VIII', 'Elizabeth I', 'Henry VII', 'Mary I'],
        answer: 'Henry VII',
      },
      {
        question: 'What year did the Battle of Hastings occur?',
        options: ['1066', '1215', '1485', '1666'],
        answer: '1066',
      },
    ],
  
    // Add more quizzes here
    mathquiz: [
      {
        question: 'What is 5 + 7?',
        options: ['10', '12', '13', '15'],
        answer: '12',
      },
      {
        question: 'What is the square root of 81?',
        options: ['7', '8', '9', '10'],
        answer: '9',
      },
      {
        question: 'What is 9 * 9?',
        options: ['72', '81', '90', '99'],
        answer: '81',
      },
    ],
  
    sciencequiz: [
      {
        question: 'What is the chemical symbol for water?',
        options: ['H2O', 'CO2', 'NaCl', 'O2'],
        answer: 'H2O',
      },
      {
        question: 'What planet is known as the Red Planet?',
        options: ['Earth', 'Mars', 'Jupiter', 'Venus'],
        answer: 'Mars',
      },
      {
        question: 'What gas do plants primarily absorb?',
        options: ['Oxygen', 'Nitrogen', 'Carbon Dioxide', 'Hydrogen'],
        answer: 'Carbon Dioxide',
      },
    ]
  };
  