import * as React from 'react';
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText, IconButton, Box, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReportIcon from '@mui/icons-material/Assessment';
import AnalysisIcon from '@mui/icons-material/Analytics';
import MarketIcon from '@mui/icons-material/BusinessCenter';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme as useCustomTheme } from '../Theme/ThemeContext'; // Adjust the import path if necessary
import aerilogo1 from '../assets/aerilogo3.png'; // Ensure this path is correct
import aerilogo2 from '../assets/aerilogo1.png'; // Ensure this path is correct

import { useMediaQuery, useTheme as useMuiTheme } from '@mui/material';

const drawerWidth = 150; // Width when expanded
const collapsedWidth = 50; // Width when collapsed on larger screens
const mobileCollapsedWidth = 0; // Width when collapsed on mobile screens

const menuItems = [
  { text: 'home', icon: <HomeIcon />, link: '/' },
  { text: 'Dashboards', icon: <DashboardIcon />, link: '/dashboard' },
  // { text: 'Reports', icon: <ReportIcon />, link: '/reports' },
  // { text: 'Analysis', icon: <AnalysisIcon />, link: '/analysis' },
  // { text: 'Data', icon: <MarketIcon />, link: '/data' },
];

function Sidebar({ open, toggleDrawer }) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const { mode } = useCustomTheme(); // Get the current theme mode

  const theme = useMuiTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    if (isSmallScreen) {
      toggleDrawer(); // Close drawer on small screens
    }
  };

  return (
    <Drawer
      anchor={isMobile ? "right" : "left"} // Anchor the drawer from the right on mobile
      open={true} // Always open for this example; adjust as needed
      variant="persistent"
      sx={{
        width: isMobile ? (open ? drawerWidth : mobileCollapsedWidth) : (open ? drawerWidth : collapsedWidth),
        flexShrink: 0,
        overflowX: 'hidden',
        position: isSmallScreen ? 'absolute' : 'relative', // Absolute for small screens, relative for medium and larger screens
        '& .MuiDrawer-paper': {
          width: isMobile ? (open ? drawerWidth : mobileCollapsedWidth) : (open ? drawerWidth : collapsedWidth),
          transition: 'width 0.3s ease', // Adjust the transition duration as needed
          color: mode === 'light' ? 'white' : 'text.primary', // Adjust text color based on theme
          overflowX: 'hidden',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: '8px',
        }}
      >
        <IconButton onClick={toggleDrawer} 
        sx={{ color: mode === 'light' ? 'black' : 'inherit' }}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Box>
      
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '16px 0',
        }}
      >
        <img 
          src={mode === 'light' ? aerilogo2 : aerilogo1} 
          alt="Aeri Logo" 
          style={{ 
            width: open ? '100px' : '50px', 
            transition: 'width 0.3s', 
            display: isMobile ? 'flex' : 'none'

          }} 
        />
      </Box>

      <Divider />
      
      <Box 
        sx={{ 
          width: '100%', 
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'column',
          justifyContent: isMobile ? 'center' : 'flex-start',
          alignItems: 'center',
          marginTop: '20px'
        }}
      >
        <List 
          component="nav" 
          aria-label="main mailbox folders"
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'column',
            padding: 0,
          }}
        >
          {menuItems.map((item, index) => (
            <ListItemButton
              key={item.text}
              component={Link}
              to={item.link}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index)}
              sx={{ 
                justifyContent: 'center', 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                padding: '10px 0',
                minWidth: isMobile ? 0 : (open ? drawerWidth : collapsedWidth),
              }}
            >
              <ListItemIcon
                sx={{
                  color: mode === 'light' ? '#393c40' : 'inherit',
                  minWidth: 40,
                  justifyContent: 'center',
                  fontSize: '2.5rem',
                  transition: 'all 0.1s',
                }}
              >
                {React.cloneElement(item.icon, { sx: { fontSize: '1.6rem' } })}
              </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    fontFamily: 'Arial, sans-serif',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    color: mode === 'light' ? 'black' : 'inherit',
                  }}
                  sx={{
                    opacity: open ? 1 : 0,
                    transition: 'opacity 0.1s',
                    whiteSpace: 'nowrap',
                    height: '1.5rem',
                    overflow: 'hidden',
                  }}
                />
            </ListItemButton>
          ))}
        </List>
        <Divider />
      </Box>
    </Drawer>
  );
}

export default Sidebar;
